/* eslint-disable import/no-anonymous-default-export */

import { ADD_Item, CHANGE_ORGER, CHANGE_TYPE, DELETE_SELECT_ITEM, FORM_SET_ERROR, FORM_SET_LOADING, GET_FORM_DATA, GET_FORM_SETTING, SELECT_ITEM, UPDATE_MAIL_CONFIGRATIONS, UPDATE_PROPERTY } from "actions/types";

const initialState = {
    error: false,
    isLoading: true,
    Selected: null,
    FormData: [],
    property: [],
    setting: {
        mail: {
            isEmail: false,
            Destination: [],
            sendCc: [],
            sendBcc: []
        }
    }
};

export default (state = initialState, action) => {
    var index = action?.payload?.selected
    switch (action.type) {
        case FORM_SET_LOADING:
            return {
                ...state,
                isLoading: action?.payload,
                error: false
            }

        case FORM_SET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true
            }
        case ADD_Item:

            //             if (action.payload.data.type === EditorFields.reCAPTCHA) {
            //                 state.FormData.map(item => {
            //                     if (item.type === EditorFields.reCAPTCHA) {
            //                         return state
            //                     }
            //                 })
            //             }


            return {
                ...state,
                property: action.payload.property,
                Selected: state.FormData.length,
                FormData: [
                    ...state.FormData,
                    action.payload.data
                ],
            };

        case CHANGE_TYPE:
            return {
                ...state,
                property: action.payload.property,
                FormData: [
                    ...state.FormData.slice(0, index),
                    {
                        ...state.FormData[index],
                        validation: action.payload.validation,
                    },
                    ...state.FormData.slice(index + 1),
                ],
            };


        case UPDATE_PROPERTY:
            return {
                ...state,
                property: action.payload.property,
                Selected: null,
                FormData: [
                    ...state.FormData.slice(0, index),
                    {
                        ...action.payload.validation
                    },
                    ...state.FormData.slice(index + 1),
                ],
            };

        case CHANGE_ORGER:
            return {
                ...state,
                FormData: action.payload,
            };
        case SELECT_ITEM:
            return {
                ...state,
                property: action.payload.property,
                Selected: action.payload.selected,
            };

        case GET_FORM_DATA:
            return {
                ...state,
                FormData: action.payload,
            }
        case GET_FORM_SETTING:
            return {
                ...state,
                setting: {
                    ...state.setting,
                    ...action.payload
                },
            }
        case DELETE_SELECT_ITEM:
            return {
                ...state,
                property: action.payload.property,
                Selected: null,
                FormData: [
                    ...state.FormData.slice(0, action.payload.selected),
                    ...state.FormData.slice(action.payload.selected + 1)
                ],
            };
        case UPDATE_MAIL_CONFIGRATIONS:
            return {
                ...state,
                setting: {
                    ...state.setting,
                    mail: {
                        ...state.setting.mail,
                        ...action.payload
                    }
                },
            }
        default:
            return state;
    }
};
